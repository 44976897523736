<template>
  <div>
    <h3 style="text-align: center; margin: 10px">CONTABILIDADE</h3>
    <div class="buttons" style="text-align: center; margin: 10px">
      <button
        @click="showSearchDetail(false)"
        class="btn btn-primary"
        style="margin-right: 5px"
      >
        Pesquisa por Fornecedor
      </button>
      <button
        @click="showSearchDetail(true)"
        class="btn btn-primary"
        style="margin-left: 5px"
      >
        Pesquisa detalhada
      </button>
    </div>
    <span v-if="!detailSearch">
      <div class="row" style="text-align: center; justify-content: center">
        <div class="col-sm-3">
          <div class="form-group center-select">
            <v-select
              :options="suppliers"
              :reduce="(supp) => supp"
              label="name"
              v-model="selectedSupplier"
            >
              <template slot="option" slot-scope="option">
                {{ option.name }}</template
              >
              <template slot="selected-option" slot-scope="option">
                {{ option.name }}</template
              >
            </v-select>
          </div>
        </div>
      </div>
      <table class="table table-sm">
        <thead>
          <tr>
            <th>Supplier</th>
            <th>Difference</th>
            <th>Nota de Crédito</th>
            <th>Total Notas de Crédito</th>
            <th>Últm. Mov.</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="supplier in suppliersWithDifference" :key="supplier.id">
            <td>{{ supplier.supplier_name }}</td>
            <td>
              <span
                :class="{
                  'text-green': supplier.total > 0,
                  'text-red': supplier.total < 0,
                }"
                ><b>{{ supplier.total }}</b></span
              >
            </td>
            <td>
              <span v-if="supplier.expecting_credit_note == true"
                >SIM</span
              >
              <span v-else>-</span
              >
            </td>
            <td><span v-if="supplier.total_credit_note > 0">{{ supplier.total_credit_note }}</span>
            <span v-else>-</span></td>
            <td>
              {{ new Date(supplier.last_date).toLocaleDateString("pt-PT") }}
            </td>
            <td>
              <button
                @click="openSupplierAccounting(supplier.id)"
                class="btn btn-primary btn-sm"
              >
                +
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </span>
    <span v-else>
      <div class="row" style="justify-content: center; text-align: center">
        <div class="col-sm-2">
          <div class="form-group">
            <label for=""><b>Data Inicial</b></label> <br />
            <input type="date" class="form-control" v-model="date_i" />
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-group">
            <label for=""><b>Data Final</b></label> <br />
            <input type="date" class="form-control" v-model="date_f" />
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-group">
            <label for=""><b>Tipo de Transação</b></label> <br />
            <select
              class="form-control custom-select"
              id="document"
              v-model="document"
            >
              <option value="Recebimento">Recebimento</option>
              <option value="Adiantamento">Adiantamento</option>
              <option value="Pagamento">Pagamento</option>
              <option value="Nota de Crédito">Notas de Crédito</option>
              <option value="Fatura">Fatura</option>
            </select>
          </div>
        </div>
        <div class="col-sm-2">
          <div>
            <span><b>Pesquisa</b></span
            ><br />
            <button class="btn btn-primary" @click="detailedSearch">
              Pesquisar
            </button>
          </div>
        </div>
      </div>
      <table class="table table-bordered table-sm">
        <thead>
          <tr>
            <th scope="col">
              Tipo Doc.
              <input
                v-model="filters.type_document"
                type="text"
                placeholder="Search"
                id="search"
              />
            </th>
            <th scope="col">
              Fornecedor
              <input
                v-model="filters.name"
                type="text"
                placeholder="Search"
                id="search"
              />
            </th>
            <th scope="col">
              Detalhe
              <input
                v-model="filters.transaction_detail"
                type="text"
                placeholder="Search"
                id="search"
              />
            </th>
            <th scope="col">
              Débito
              <input
                v-model="filters.debit"
                type="text"
                placeholder="Search"
                id="search"
              />
            </th>
            <th scope="col">
              Credito
              <input
                v-model="filters.credit"
                type="text"
                placeholder="Search"
                id="search"
              />
            </th>
            <th scope="col">
              Receção
              <input
                v-model="filters.reception_id"
                type="text"
                placeholder="Search"
                id="search"
              />
            </th>
            <th scope="col" style="width: 200px;">Data</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(data, index) in filteredDatas" :key="index">
            <td>{{ data.type_document }}</td>
            <td>{{ data.name }}</td>
            <td>{{ data.transaction_detail }}</td>
            <td>{{ data.debit }}</td>
            <td>{{ data.credit }}</td>
            <td>{{ data.reception_id }}</td>
            <td>{{ new Date(data.date_insert).toLocaleString("pt-PT") }}</td>
            <!-- Add more columns as needed -->
          </tr>
        </tbody>
      </table>
    </span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      suppliersWithDifference: [],
      suppliers: [],
      selectedSupplier: null,
      detailSearch: false,
      date_i: "",
      date_f: "",
      document: "",
      accountingSearch: [],
      filters: {
        type_document: "",
        name: "",
        transaction_detail: "",
        debit: "",
        credit: "",
        reception_id: "",
        // Add more filters as needed
      },
    };
  },
  methods: {
    async showSearchDetail(value) {
      this.detailSearch = value;
    },
    async allSuppliersWithDifference() {
      try {
        await this.$store.dispatch("accountingAllSuppliersWithDifference");
        console.log("AQUI");
        this.suppliersWithDifference =
          this.getAccountingAllSuppliersWithDifference;
        console.log(this.getAccountingAllSuppliersWithDifference);
      } catch (error) {
        alert("Error: " + error);
      }
    },
    async openSupplierAccounting(supplier) {
      this.$router.push(`/accounting/supplier/${supplier}`);
    },
    async getAllSuppliers() {
      try {
        await this.$store.dispatch("getAllSuppliers");
        console.log(this.getSuppliers);
        let obj;
        for (let index = 0; index < this.getSuppliers.length; index++) {
          const element = this.getSuppliers[index];
          obj = {
            id: element.id,
            name: `${element.name} (${element.other_name})`,
          };
          this.suppliers.push(obj);
        }
      } catch (error) {
        alert(error);
      }
    },
    async handleSelectChange(selectedOption) {
      console.log(selectedOption);
      if (selectedOption == null || selectedOption == undefined) {
        await this.allSuppliersWithDifference();
        return;
      }
      try {
        await this.$store.dispatch("filterBySupplierAccounting", {
          id: selectedOption,
        });
        console.log("AQUI");
        this.suppliersWithDifference =
          this.getAccountingAllSuppliersWithDifference;
        console.log(this.getAccountingAllSuppliersWithDifference);
      } catch (error) {
        alert("Error: " + error);
      }
    },
    async detailedSearch() {
      console.log(this.date_i, this.date_f);
      if (this.date_f && !this.date_i) {
        alert("Please select the initial and final date");
        return;
      }
      try {
        await this.$store.dispatch("searchByDetailsAccounting", {
          date_start: this.date_i,
          date_end: this.date_f,
          document: this.document,
        });
        this.accountingSearch = this.getAccountingDetails;
      } catch (error) {
        alert("Error: " + error);
      }
    },
  },
  computed: {
    ...mapGetters(["getAccountingAllSuppliersWithDifference"]),
    ...mapGetters(["getSuppliers"]),
    ...mapGetters(["getAccountingDetails"]),
    filteredDatas() {
      return this.accountingSearch.filter((data) =>
        Object.keys(this.filters).every((key) =>
          String(data[key])
            .toLowerCase()
            .includes(this.filters[key].toLowerCase())
        )
      );
    },
  },
  watch: {
    async selectedSupplier(newVal) {
      if (newVal === null || newVal === undefined) {
        await this.handleSelectChange(null);
      } else {
        await this.handleSelectChange(newVal.id);
      }
    },
  },
  created() {
    this.allSuppliersWithDifference();
    this.getAllSuppliers();
  },
};
</script>

<style scoped>
.table {
  text-align: center;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}
.text-green {
  color: green;
}
.text-red {
  color: red;
}
td {
  font-size: 13px;
}
#search {
  width: 100%;
}
</style>
